import React from 'react';

import { AxiosError } from 'axios';
import { produce } from 'immer';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import { Project } from 'types/projectOffer.type';

import { ProjectOfferRequestAction } from '../components/projectOffersList';
import { CalendarDrawerTypes } from '@consts/drawers';
import { ProjectOfferSnackbarMessage } from '@features/projectOffers/components/snackbar';
import { ProjectOfferSnackbarActions } from '@features/projectOffers/components/snackbarActions';
import {
  getUserAccountId,
  selectedEventId,
  selectedOfferId,
  setDrawerData,
} from '@recoilData/index';
import api from '@services/api';

export enum LocationAction {
  offerList = 'offerList',
  offerDetail = 'offerDetail',
}

type ProjectOffers = {
  [key: string]: Project[];
};

type MutationError = {
  reason: string;
};
type ProjectRequestMutationError = AxiosError & MutationError;

const SnackbarColorByAction = {
  accept: 'success',
  reject: 'error',
};
const SnackbarSuccessMessageByAction = {
  accept: 'accept',
  reject: 'reject',
};

const SnackbarMutatingMessageByAction = {
  accept: 'acceptingRequest',
  reject: 'rejectingRequest',
};

const SnackbarStyles = (action: ProjectOfferRequestAction) => ({
  style: {
    marginRight: '-1.2rem',
    marginLeft: '1.2rem',
    maxHeight: '2.5rem',
    width: '418px',
    zIndex: -2,
    marginBottom: '2.5rem',
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
});

export const useProjectOfferMutation = () => {
  const accountId = useRecoilValue(getUserAccountId);
  const queryClient = useQueryClient();
  const setEventId = useSetRecoilState(selectedEventId);
  const resetOfferId = useResetRecoilState(selectedOfferId);
  const [drawers, setDrawerOpen] = useRecoilState(setDrawerData);

  const UNDO_TIMEOUT = 3;

  const { mutate, isLoading: isMutating } = useMutation(
    ({
      projectOfferID,
      action,
    }: {
      projectOfferID: number;
      action: ProjectOfferRequestAction;
      openTask: boolean;
      location: 'offerList' | 'offerDetail';
    }) => {
      return new Promise((resolve, reject) => {
        const doMutation = async (callback?: (offerID: number) => void) => {
          try {
            const { data } = await api.post(`/projectOffer/${action}`, {
              account_id: accountId,
              project_offer_id: projectOfferID,
            });
            resolve(data);
            if (callback) {
              callback(data.id);
            }
          } catch (error) {
            reject(error);
          }
        };

        const cancelMutation = () => {
          reject({ reason: 'mutationCancelled' });
        };

        enqueueSnackbar({
          variant: 'snackBarProgress',
          ...SnackbarStyles(action),
          message: (
            <ProjectOfferSnackbarMessage
              translationToken={SnackbarMutatingMessageByAction[action]}
            />
          ),
          // eslint-disable-next-line react/display-name
          action: (key: string) => (
            <ProjectOfferSnackbarActions
              snackBarId={key}
              action={action}
              closeSnackbar={closeSnackbar}
              cancelMutation={cancelMutation}
              doMutation={doMutation}
            />
          ),
          onClose: (_, reason) => {
            if (reason === 'timeout' && reason !== 'instructed') {
              doMutation((offerID) => {
                setEventId(offerID);
              });
            }
          },
          hideIconVariant: true,
          colorByAction: SnackbarColorByAction[action],
          undoableTimeout: UNDO_TIMEOUT,
          autoHideDuration: UNDO_TIMEOUT * 1000,
        });
      });
    },
    {
      mutationKey: 'updateProjectOffer',
      onMutate: async ({ projectOfferID }) => {
        await queryClient.cancelQueries({
          queryKey: ['accountProjectOffers', accountId],
        });

        const previousOffers: ProjectOffers = queryClient.getQueryData([
          'accountProjectOffers',
          accountId,
        ]);

        queryClient.setQueryData(
          ['accountProjectOffers', accountId],
          (oldOffers: ProjectOffers) => {
            return produce(oldOffers, (draft) => {
              for (const date in draft) {
                const tmpProjects = draft[date].filter(
                  (project) => project.id !== projectOfferID
                );

                if (tmpProjects.length === 0) {
                  delete draft[date];
                } else {
                  draft[date] = tmpProjects;
                }
              }
            });
          }
        );

        return () => {
          queryClient.setQueryData(
            ['accountProjectOffers', accountId],
            previousOffers
          );
        };
      },
      onSuccess: (data: Project, variables) => {
        queryClient.setQueryData(
          ['accountProjectOffers', accountId],
          (oldOffers: ProjectOffers) => {
            return produce(oldOffers, (draft) => {
              for (const date in draft) {
                const tmpProjects = draft[date].filter(
                  (project) => project.id !== variables.projectOfferID
                );

                if (tmpProjects.length === 0) {
                  delete draft[date];
                } else {
                  draft[date] = tmpProjects;
                }
              }
            });
          }
        );
        enqueueSnackbar({
          hideIconVariant: false,
          variant: 'snackBarProgress',
          ...SnackbarStyles(variables.action),
          onClose: () => {
            if (variables.openTask) {
              setEventId(data.id);
            }
          },
          autoHideDuration: (UNDO_TIMEOUT * 1000) / 2,
          colorByAction: SnackbarColorByAction[variables.action],
          message: (
            <ProjectOfferSnackbarMessage
              translationToken={
                SnackbarSuccessMessageByAction[variables.action]
              }
            />
          ),
        });

        if (
          variables.location === LocationAction.offerDetail &&
          variables.action === ProjectOfferRequestAction.Reject
        ) {
          resetOfferId();
          setDrawerOpen({
            isOpen: !drawers.listOffers,
            drawer: CalendarDrawerTypes.listOffers,
          });
        }

        queryClient.invalidateQueries('accountEvents');
      },
      onError: (err: ProjectRequestMutationError, variables, restoreCache) => {
        // @ts-ignore
        restoreCache();

        if (err?.reason !== 'mutationCancelled') {
          enqueueSnackbar({
            variant: 'snackBarProgress',
            colorByAction: 'requestError',
            ...SnackbarStyles(ProjectOfferRequestAction.Reject),
            message: (
              <ProjectOfferSnackbarMessage translationToken="requestError" />
            ),
          });
        }
        if (
          variables.location === 'offerDetail' &&
          variables.action === ProjectOfferRequestAction.Reject
        ) {
          resetOfferId();
          setDrawerOpen({
            isOpen: !drawers.listOffers,
            drawer: CalendarDrawerTypes.listOffers,
          });
        }
        queryClient.invalidateQueries('accountEvents');
        queryClient.invalidateQueries(['accountProjectOffers', accountId]);
      },
    }
  );

  const handleProjectAction = (
    projectOfferID: number,
    action: ProjectOfferRequestAction,
    openTask?: boolean,
    location?: LocationAction
  ) => {
    mutate({ projectOfferID, action, openTask, location });
  };

  return {
    handleProjectAction,
    isMutating,
  };
};

import React, { useState, useEffect, useMemo } from 'react';

import { useQueryClient } from 'react-query';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';

import { DeleteTaskModal, MarkAsCompleteModal } from '../components/index';
import {
  compareDates,
  compareAssignees,
  compareChecklists,
  compareByJSONStrigify,
} from '../helpers';
import { useSnackbar } from '@components/TkSnackbar/useSnackbar';
import { EventTypeEnum, TaskStatusEnum } from '@consts/index';
import { useLoading, useModal } from '@contexts/index';
import { useEditTaskValidation } from '@features/editTask/hooks';
import { getDateToEpoch, userTimezone } from '@helpers/index';
import { useDrawer } from '@hooks/index';
import { useContactCustomerService } from '@hooks/useCustomerService';
import {
  event as eventRecoil,
  resetEvent,
  editEvent,
  isEventEdit,
  editEventEnd,
  editEventStart,
  resetEditEvent,
  editEventNotes,
  editEventTitle,
  isEventDeleted,
  editEventImages,
  selectedEventId,
  editEventCustomer,
  editEventLocation,
  editEventImagesFile,
  editEventTeammatesImageCount,
  getEditEventAssigneesCount,
  getSelectedEditEventAssignees,
  getSelectedEditEventChecklists,
  editEventSelectedChecklist,
} from '@recoilData/index';
import { eventMethods } from '@services/index';

export const useEditTaskDrawer = () => {
  const { toggleShowLoading } = useLoading();
  const {
    closeDrawers,
    closeExtDrawer,
    callDrawer,
    currentDrawer: { extended },
    types: { ExtendedDrawersTypes },
  } = useDrawer();
  const {
    deleteRepeatingTask,
    deleteTask,
    removeImageFromTask,
    updateEventApi,
    uploadEventImages,
  } = eventMethods;
  const { fireTError, fireTSuccess } = useSnackbar();
  const { handleEditTask } = useEditTaskValidation();
  const { setupModal, handleCloseModal } = useModal();
  const [allDayEnd, setAllDayEnd] = useState<boolean>(false);
  const [allDayStart, setAllDayStart] = useState<boolean>(false);
  const [showEditCustomer, setShowEditCustomer] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { handleNewCustomerServiceMessage } = useContactCustomerService();

  // Recoil Values
  const event = useRecoilValue(eventRecoil);
  const end = useRecoilValue(editEventEnd);
  const name = useRecoilValue(editEventTitle);
  const [start, setStart] = useRecoilState(editEventStart);
  const notes = useRecoilValue(editEventNotes);
  const images = useRecoilValue(editEventImages);
  const selectedEvent = useRecoilValue(editEvent);
  const setEventId = useSetRecoilState(selectedEventId);
  const resetMainEvent = useResetRecoilState(resetEvent);
  const imagesFile = useRecoilValue(editEventImagesFile);
  const [edited, setEdited] = useRecoilState(isEventEdit);
  const [deleted, setDeleted] = useRecoilState(isEventDeleted);
  const resetEventId = useResetRecoilState(selectedEventId);
  const resetSelectedEvent = useResetRecoilState(resetEditEvent);
  const [customer, setCustomer] = useRecoilState(editEventCustomer);
  const checklists = useRecoilValue(getSelectedEditEventChecklists);
  const [location, setLocation] = useRecoilState(editEventLocation);
  const assignedUsers = useRecoilValue(getSelectedEditEventAssignees);
  const assignedUsersCount = useRecoilValue(getEditEventAssigneesCount);
  const setImgCount = useSetRecoilState(editEventTeammatesImageCount);
  const setSelectedChecklist = useSetRecoilState(editEventSelectedChecklist);

  const hasPendingMandatoryChecklist = useMemo(
    () =>
      event.checklists
        .filter((checklist) => checklist.isMandatory === 1)
        .map((checklist) =>
          checklist.completedItemsCount !== checklist.numberOfItems
            ? checklist
            : null
        ),
    [event]
  );

  const hasPendingAssignees = useMemo(
    () =>
      assignedUsers.filter(
        (assignee) => assignee.assignmentStatus !== TaskStatusEnum.DONE
      ),
    [assignedUsers]
  );

  const closeModalDelete = () => {
    setDeleted(true);
    handleCloseModal();
  };

  useEffect(() => {
    if (event.deletedAt) {
      setDeleted(true);
    }
  }, [event]);

  const isProvidedDrawerOpen = (drawer: string) =>
    extended && extended.id === drawer;

  useEffect(() => {
    setEditedIfDifferent(event.notes, notes);
  }, [event.notes, notes]);

  useEffect(() => {
    setEditedIfDifferent(event.start, start, compareDates);
    setEditedIfDifferent(event.end, end, compareDates);
  }, [start, end]);

  useEffect(() => {
    setEditedIfDifferent(event.customer, customer);
  }, [customer]);

  useEffect(() => {
    setEditedIfDifferent(event.images, images);
  }, [images]);

  useEffect(() => {
    setEditedIfDifferent(event.location, location);
  }, [location]);

  useEffect(() => {
    setEditedIfDifferent(event.checklists, checklists, compareChecklists);
  }, [checklists]);

  useEffect(() => {
    setEditedIfDifferent(event.assignedUsers, assignedUsers, compareAssignees);
  }, [assignedUsers]);

  const setEditedIfDifferent = (
    initial,
    editable,
    compareCallback = compareByJSONStrigify
  ) => {
    const objectsPristine = compareCallback(initial, editable);
    if (!objectsPristine && !edited) {
      setEdited(true);
    }
  };

  useEffect(() => {
    let imageCount = 0;

    selectedEvent.crewAssignmentsImages.forEach((teammatesImages) => {
      imageCount = imageCount + teammatesImages.images.length;
    });

    setImgCount(imageCount);
  }, [selectedEvent]);

  const validateStartDate = (): boolean => {
    const importedStartDate = event?.systemNotes?.original_start_time_epoch;
    const parsedStart = Date.parse(start.toString()) / 1000;
    if (importedStartDate && parsedStart < importedStartDate) {
      handleEditTask(event, 'editTaskToBefore');
      setStart(new Date(importedStartDate * 1000));
      return false;
    }
    if (start > end) {
      fireTError('startTimeBeforeEndTimeWarning', { ns: 'events' });
      return false;
    }
    return true;
  };

  const handleResponse = (response) => {
    if (response) {
      setEdited(false);
      queryClient.refetchQueries('getEvent');
      queryClient.invalidateQueries('accountEvents');
    }
    toggleShowLoading(false);
  };

  const handleErrorResponse = (response) => {
    switch (response.message) {
      case 'pendingMandatoryChecklists': {
        setupModal({
          content: (
            <MarkAsCompleteModal
              action={continueMandatoryChecklist}
              type="mandatoryChecklist"
            />
          ),
        });
        break;
      }

      case 'pendingInventory': {
        break;
      }

      default:
        fireTError('error.somethingWentWrong', { ns: 'app' });
        break;
    }

    toggleShowLoading(false);
  };

  const handleSave = async () => {
    closeExtDrawer();

    if (allDayStart || allDayEnd) {
      fireTError('dateMustBeSet', {
        ns: 'events',
        period: allDayStart ? 'Start' : 'End',
      });
      return;
    }

    if (validateStartDate()) {
      const updatedEvent: IEventUpdateApi = {
        timezone: userTimezone(),
      };
      toggleShowLoading(true);

      updatedEvent.start_time_epoch = getDateToEpoch(start).toString();
      updatedEvent.end_time_epoch = getDateToEpoch(end).toString();

      if (name !== event.title) {
        updatedEvent.name = name;
      }

      if (notes !== event.notes) {
        updatedEvent.notes = notes;
      }

      if (customer !== event.customer) {
        updatedEvent.customer_id = customer === undefined ? null : customer.id;
      }

      if (location !== event.location) {
        updatedEvent.location_id = location === undefined ? null : location.id;
      }

      if (images !== event.images) {
        const difference = event.images.filter((x) => !images.includes(x));
        difference.forEach((i) => {
          removeImageFromTask(i.id).then((res) => res);
        });
      }

      const newChecklists: IChecklistApi[] = [];
      const newAssignees: number[] = [];

      checklists.forEach((c) => {
        newChecklists.push({
          id: c.id,
          is_mandatory: c.isMandatory ? 1 : 0,
        });
      });

      assignedUsers.forEach((a) => {
        newAssignees.push(a.id);
      });

      updatedEvent.checklists = newChecklists;
      updatedEvent.user_id = newAssignees;
      updatedEvent.pictures_ids = [];

      try {
        if (imagesFile.length > 0) {
          uploadEventImages(imagesFile).then(({ images }) => {
            images.forEach((image) => {
              if (image) {
                updatedEvent.pictures_ids?.push(image.id);
              }
            });
          });
        }
        updateEventApi(event.id, updatedEvent)
          .then((res) => handleResponse(res))
          .catch((res) => handleErrorResponse(res));
      } catch ({ data }) {
        handleErrorResponse(data);
      }
    }
  };

  const handleDismiss = () => {
    setEventId(0);
    closeDrawers();
    resetEventId();
    resetMainEvent();
    resetSelectedEvent();
  };

  const handleCustomer = () => {
    const handleCallDrawer = () =>
      callDrawer({
        extended: {
          id: ExtendedDrawersTypes.EDIT_TASK_CUSTOMER,
        },
      });
    handleEditTask(null, 'customerVisibility', handleCallDrawer);
  };

  const handleLocation = () =>
    callDrawer({
      extended: {
        id: ExtendedDrawersTypes.EDIT_TASK_LOCATION,
      },
    });

  const handleTaskNotes = () =>
    callDrawer({
      extended: {
        id: ExtendedDrawersTypes.EDIT_TASK_NOTES,
      },
    });

  const handleImages = () =>
    callDrawer({
      extended: {
        id: ExtendedDrawersTypes.EDIT_TASK_IMAGES,
      },
    });

  const removeCustomer = () => {
    const handleRemoveCustomer = () => {
      setCustomer(undefined);
      setLocation(undefined);
    };
    handleEditTask(null, 'customerVisibility', handleRemoveCustomer);
  };

  const continueMandatoryChecklist = () => {
    setSelectedChecklist(hasPendingMandatoryChecklist[0]);
    handleCloseModal();
    callDrawer({
      extended: {
        id: ExtendedDrawersTypes.EDIT_TASK_CHECKLIST_LIST,
        props: {
          eventType: EventTypeEnum.EDIT,
        },
      },
    });
  };

  const handleMarkAsComplete = (
    isLastAssigneNotCompleted = false,
    type = 'markAsComplete'
  ) => {
    const continueMarkAsComplete = async (completeForEveryone = true) => {
      handleCloseModal();

      if (hasPendingMandatoryChecklist.length > 0) {
        setupModal({
          content: (
            <MarkAsCompleteModal
              action={continueMandatoryChecklist}
              type="mandatoryChecklist"
            />
          ),
        });
        return;
      }

      try {
        await eventMethods.markAsComplete(event.id, completeForEveryone);
        await queryClient.refetchQueries('getEvent');
        await queryClient.refetchQueries('accountEvents');

        fireTSuccess('taskMarkedAsComplete', { ns: 'events' });
      } catch (error) {
        console.error(error);
      }
    };

    if (hasPendingAssignees.length == 1 && isLastAssigneNotCompleted) {
      const modalType =
        type == 'lastNotCompleted' ? 'lastNotCompleted' : 'lastInProgress';
      setupModal({
        content: (
          <MarkAsCompleteModal
            action={() => continueMarkAsComplete(false)}
            type={modalType}
            count={hasPendingAssignees.length}
            assigneesNames={hasPendingAssignees[0].first_name}
          />
        ),
      });
      return;
    }

    if (hasPendingAssignees.length > 0) {
      setupModal({
        content: (
          <MarkAsCompleteModal
            type="markAsComplete"
            action={() => continueMarkAsComplete(true)}
          />
        ),
      });
      return;
    }
  };

  const handleAssignee = () =>
    callDrawer({
      extended: {
        id: ExtendedDrawersTypes.TASK_ASSIGNEE_LIST,
        props: {
          eventType: EventTypeEnum.EDIT,
        },
      },
    });

  const handleProjectAdditionalInfoTurno = () =>
    callDrawer({
      extended: {
        id: ExtendedDrawersTypes.EDIT_TASK_ADDITIONAL_INFO_TURNO,
      },
    });

  const handleContactCostumerSupport = () =>
    handleNewCustomerServiceMessage(event.id, event.customer?.name);

  const onMouseEnter = () => setShowEditCustomer(true);
  const onMouseLeave = () => setShowEditCustomer(false);

  const openDeleteTaskModal = () => {
    const handleCallModal = () =>
      setupModal({
        content: (
          <DeleteTaskModal
            event={event}
            deleteTask={deleteTask}
            deleteRepeatingTask={deleteRepeatingTask}
            handleCloseModalDeleted={closeModalDelete}
          />
        ),
      });
    handleEditTask(event, 'deleteTask', handleCallModal);
  };

  return {
    event,
    deleted,
    customer,
    selectedEvent,
    allDayEnd,
    allDayStart,
    setAllDayEnd,
    setAllDayStart,
    showEditCustomer,
    handleCustomer,
    removeCustomer,
    assignedUsers,
    assignedUsersCount,
    handleAssignee,
    handleMarkAsComplete,
    handleProjectAdditionalInfoTurno,
    onMouseEnter,
    onMouseLeave,
    handleLocation,
    handleTaskNotes,
    images,
    imagesFile,
    handleImages,
    edited,
    handleSave,
    handleDismiss,
    openDeleteTaskModal,
    isProvidedDrawerOpen,
    ExtendedDrawersTypes,
    handleContactCostumerSupport,
  };
};

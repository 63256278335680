import React, { useMemo } from 'react';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { Box, Grid, Divider } from '@mui/material';

import TkBookingProjectAdditionalInfoItem from './components/TkBookingProjectAdditionalInfoItem';
import {
  TkDeletedEvent,
  TkLoadingEvent,
  TkEditTaskNotes,
  TkAssigneeSelectedListItem,
  TkEditNameDisplay,
  TkTaskTags,
  TkStatusAndTimeDisplay,
  TkTeamNotesAndImages,
  TkTaskInventory,
  TkTaskChecklists,
} from './components/index';
import { TkTaskWrapperSubItem } from './components/styles';
import { useBookingProject } from './hooks/useBookingProject';
import { useEditTaskDrawer } from './hooks/useEditTaskDrawer';
import {
  TkTaskWrapper,
  TkTaskWrapperItem,
  HorizontalDivider,
  Selector,
  TkTaskActions,
  ImagePreviewCircle,
} from './styles';
import {
  TkDrawerHeader,
  TkTaskWrapperItem as TkTaskItem,
} from '@components/TkDrawerComponents';
import { TkDrawerTurnoAdditionalInfo } from '@components/TkDrawerComponents/TkDrawerTurnoAdditionalInfo';
import { ITkExtendedOfferDetailsDrawerEnum } from '@components/TkOfferDetailsDrawer/TkExtendedOffersDetailsDrawer';
import { CrewMember } from '@components/TkTeams/types';
import {
  TkIcon,
  TkTypography,
  DynamicButton,
  TkDrawerPhone,
  TkDrawerLocationDetails,
  TkAssigneeButton,
  TkDrawerServiceDetails,
} from '@components/index';
import { TaskStatusEnum, TaskbirdIntegrationsEnum } from '@consts/index';
import { useAuth } from '@contexts/index';
import { getOfferAdditionalItems } from '@helpers/offers';
import { useStartChat } from '@hooks/index';
import { TkIconSelected } from '@web-ui/index';

const TkEditTaskDrawer = (): JSX.Element => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const loading = queryClient.isFetching('getEvent');
  const {
    event,
    deleted,
    customer,
    selectedEvent,
    allDayEnd,
    allDayStart,
    setAllDayEnd,
    setAllDayStart,
    showEditCustomer,
    handleCustomer,
    removeCustomer,
    assignedUsers,
    assignedUsersCount,
    handleAssignee,
    onMouseEnter,
    onMouseLeave,
    handleLocation,
    handleTaskNotes,
    images,
    imagesFile,
    handleImages,
    edited,
    handleSave,
    handleDismiss,
    openDeleteTaskModal,
    handleProjectAdditionalInfoTurno,
    isProvidedDrawerOpen,
    ExtendedDrawersTypes,
    handleMarkAsComplete,
    handleContactCostumerSupport,
  } = useEditTaskDrawer();

  const { userInfo } = useAuth();
  const { handleStartChat } = useStartChat();
  const { handleProjectAdditionalInfo, handleProjectDetails } =
    useBookingProject();

  const isStatusTodo = event.status === TaskStatusEnum.TODO;
  const isStatusDone = event.status === TaskStatusEnum.DONE;
  const isStatusInProgress = event.status === TaskStatusEnum.IN_PROGRESS;

  const isEventBooking =
    selectedEvent.platform &&
    selectedEvent.platform === TaskbirdIntegrationsEnum.BOOKING_WIDGET;

  const isTaskFromMoveout =
    selectedEvent.platform &&
    selectedEvent.platform === TaskbirdIntegrationsEnum.MOVEOUT;

  const eligibleAssigneesToChat = assignedUsers.filter(
    (assignee) => assignee.chat_user_id && assignee.id !== userInfo?.user.id
  );

  const shouldShowMarkCompleteForEveryone = useMemo(
    () =>
      (DateTime.now() >= DateTime.fromJSDate(event.start) && !isStatusDone) ||
      isStatusInProgress,
    [event, isStatusDone, isStatusInProgress]
  );

  const handleStartChatWithAssignees = () => {
    const channelID = `task-${selectedEvent.id}-${userInfo?.account.id}`;
    handleStartChat(
      eligibleAssigneesToChat.map((assignee) =>
        assignee.chat_user_id.toString()
      ),
      eligibleAssigneesToChat as CrewMember[],
      channelID
    );
  };

  const countAssignedUsersInProgress = assignedUsers.filter(
    (assignedUser) =>
      assignedUser.assignmentStatus === TaskStatusEnum.IN_PROGRESS
  );

  const countAssignedUsersCompleted = assignedUsers.filter(
    (assignedUser) => assignedUser.assignmentStatus === TaskStatusEnum.DONE
  );

  const countAssignedUsersNotCompleted = assignedUsers.filter(
    (assignedUser) =>
      assignedUser.assignmentStatus !== TaskStatusEnum.DONE &&
      assignedUser.assignmentStatus !== TaskStatusEnum.IN_PROGRESS
  );

  if (loading) {
    return <TkLoadingEvent />;
  } else if (deleted && !loading) {
    return (
      <TkDeletedEvent events={selectedEvent} handleDismiss={handleDismiss} />
    );
  } else {
    return (
      <>
        <TkDrawerHeader
          id={event.id}
          taskId
          showMoreActions={{
            enable: true,
            markCompleteForEveryone: shouldShowMarkCompleteForEveryone,
          }}
          handlers={{
            handleClose: handleDismiss,
            handleDeleteTask: openDeleteTaskModal,
            handleMarkAsComplete,
            handleContactCostumerSupport,
          }}
        />

        <TkTaskWrapper>
          <TkEditNameDisplay />
          <TkTaskTags event={selectedEvent} />
          <TkStatusAndTimeDisplay
            allDayEnd={allDayEnd}
            allDayStart={allDayStart}
            selectedEvent={selectedEvent}
            setAllDayEnd={setAllDayEnd}
            setAllDayStart={setAllDayStart}
          />
          <TkTeamNotesAndImages selectedEvent={selectedEvent} />
          {selectedEvent.inventory && (
            <TkTaskInventory inventory={selectedEvent.inventory} />
          )}
          <TkTaskChecklists
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
          {isEventBooking && (
            <TkTaskWrapperItem padding="5px 1rem">
              <HorizontalDivider />
              <TkTaskWrapperSubItem
                style={{ cursor: 'pointer' }}
                onClick={handleProjectDetails}
                color={
                  isProvidedDrawerOpen(
                    ITkExtendedOfferDetailsDrawerEnum.SERVICE_DETAILS
                  )
                    ? 'selected'
                    : ''
                }
              >
                <TkDrawerServiceDetails price={selectedEvent.rate ?? 0} />
              </TkTaskWrapperSubItem>
            </TkTaskWrapperItem>
          )}
          <TkTaskWrapperItem padding="5px 1rem">
            <HorizontalDivider />
          </TkTaskWrapperItem>
          <TkTaskWrapperItem
            bgcolor={
              isProvidedDrawerOpen(ExtendedDrawersTypes.EDIT_TASK_CUSTOMER)
                ? 'selected'
                : ''
            }
            padding="10px 1rem"
          >
            <TkTypography
              fontSize={16}
              fontFamily="Lato"
              fontWeight="normal"
              color={
                isProvidedDrawerOpen(ExtendedDrawersTypes.EDIT_TASK_CUSTOMER)
                  ? 'white'
                  : 'default'
              }
              display="inline"
            >
              <Grid
                container
                justifyContent="space-between"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                {customer ? (
                  <>
                    <Box
                      width="auto"
                      position="relative"
                      sx={{ maxWidth: isStatusTodo ? '350px' : 'none' }}
                    >
                      <Box fontSize={16} display="inline" marginRight="5px">
                        <TkIcon
                          className="fa fa-user-tie"
                          color={
                            isProvidedDrawerOpen(
                              ExtendedDrawersTypes.EDIT_TASK_CUSTOMER
                            )
                              ? 'white'
                              : 'default'
                          }
                          fontSize="inherit"
                        />
                      </Box>
                      {t('customer', { ns: 'common' })}:
                      <TkTypography
                        fontSize={16}
                        display="inline"
                        fontFamily="Lato"
                        fontWeight="bold"
                        color={
                          isProvidedDrawerOpen(
                            ExtendedDrawersTypes.EDIT_TASK_CUSTOMER
                          )
                            ? 'white'
                            : 'default'
                        }
                        marginLeft={1}
                      >
                        {customer.name}
                        {showEditCustomer && isStatusTodo ? (
                          <TkIconSelected
                            className="fa fa-pencil"
                            verticalAlign="middle"
                            onClick={handleCustomer}
                          />
                        ) : (
                          ''
                        )}
                      </TkTypography>
                    </Box>
                    {isStatusTodo ? (
                      <Box fontSize={16}>
                        <TkIcon
                          style={{ cursor: 'pointer' }}
                          className="fa fa-trash"
                          onClick={removeCustomer}
                          color={
                            isProvidedDrawerOpen(
                              ExtendedDrawersTypes.EDIT_TASK_CUSTOMER
                            )
                              ? 'white'
                              : 'default'
                          }
                          fontSize="inherit"
                        />
                      </Box>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <>
                    <Box width="auto">
                      <Box fontSize={16} display="inline" marginRight="5px">
                        <TkIcon
                          className="fa fa-user-tie"
                          color={
                            isProvidedDrawerOpen(
                              ExtendedDrawersTypes.EDIT_TASK_CUSTOMER
                            )
                              ? 'white'
                              : 'default'
                          }
                          fontSize="inherit"
                        />
                      </Box>
                      {t('customer', { ns: 'common' })}:
                    </Box>
                    {event.status === TaskStatusEnum.IN_PROGRESS ? (
                      ''
                    ) : (
                      <Box>
                        <TkTypography
                          fontSize={16}
                          fontWeight="bold"
                          fontFamily="Lato"
                          userselect="none"
                          onClick={handleCustomer}
                          color={
                            isProvidedDrawerOpen(
                              ExtendedDrawersTypes.EDIT_TASK_CUSTOMER
                            )
                              ? 'white'
                              : 'primary'
                          }
                          textAlign="right"
                          style={{ cursor: 'pointer' }}
                        >
                          + {t('customer', { ns: 'common' })}
                        </TkTypography>
                      </Box>
                    )}
                  </>
                )}
              </Grid>
            </TkTypography>
          </TkTaskWrapperItem>
          <TkTaskWrapperItem padding="5px 1rem">
            <HorizontalDivider />
          </TkTaskWrapperItem>
          {selectedEvent.customer?.phone_number && selectedEvent.platform && (
            <>
              <TkTaskWrapperItem padding="10px 1rem 5px">
                <TkDrawerPhone
                  phoneNumber={selectedEvent.customer.phone_number}
                />
              </TkTaskWrapperItem>
              <TkTaskWrapperItem padding="5px 1rem">
                <HorizontalDivider />
              </TkTaskWrapperItem>
            </>
          )}
          <TkTaskWrapperItem
            padding="10px 1rem"
            bgcolor={
              isProvidedDrawerOpen(ExtendedDrawersTypes.EDIT_TASK_LOCATION)
                ? 'selected'
                : ''
            }
          >
            <TkDrawerLocationDetails
              location={selectedEvent.location}
              streetOnly={isTaskFromMoveout}
              selected={isProvidedDrawerOpen(
                ExtendedDrawersTypes.EDIT_TASK_LOCATION
              )}
              editButton={
                <TkIconSelected
                  style={{ cursor: 'pointer' }}
                  className="fa fa-pencil"
                  verticalAlign="middle"
                  onClick={handleLocation}
                />
              }
              editable={isStatusTodo}
            />
          </TkTaskWrapperItem>
          <TkTaskWrapperItem padding="5px 1rem">
            <HorizontalDivider />
          </TkTaskWrapperItem>
          <TkTaskWrapperItem
            bgcolor={
              isProvidedDrawerOpen(ExtendedDrawersTypes.TASK_ASSIGNEE_LIST)
                ? 'selected'
                : ''
            }
            padding="10px 1rem"
          >
            <Box display="flex" justifyContent="space-between">
              <TkTypography fontFamily="Lato" fontSize={16}>
                <TkIcon
                  className="fa fa-user"
                  iconSize={14}
                  color={
                    isProvidedDrawerOpen(
                      ExtendedDrawersTypes.TASK_ASSIGNEE_LIST
                    )
                      ? 'white'
                      : 'default'
                  }
                  marginRight={6}
                />
                {t('assignedTo', { ns: 'events' })}:
              </TkTypography>
              {!isStatusDone && assignedUsersCount >= 1 && (
                <Selector onClick={handleAssignee}>
                  <TkTypography
                    fontWeight="bold"
                    fontFamily="Lato"
                    fontSize={16}
                    userselect="none"
                    color={
                      isProvidedDrawerOpen(
                        ExtendedDrawersTypes.TASK_ASSIGNEE_LIST
                      )
                        ? 'white'
                        : 'primary'
                    }
                  >
                    + {t('teammates', { ns: 'common' })}
                  </TkTypography>
                </Selector>
              )}
            </Box>
          </TkTaskWrapperItem>
          <TkTaskWrapperItem padding="5px 1rem 5px 1rem">
            {assignedUsers.map((assignee) => (
              <TkAssigneeSelectedListItem
                key={assignee.id}
                assignee={assignee}
                numberAssigneesInProgress={countAssignedUsersInProgress.length}
                numberAssigneesCompleted={countAssignedUsersCompleted.length}
                numberAssigneesNotCompleted={
                  countAssignedUsersNotCompleted.length
                }
                handleMarkAsComplete={handleMarkAsComplete}
              />
            ))}
            {assignedUsersCount === 0 && (
              <TkAssigneeButton handleClick={handleAssignee} />
            )}
            {eligibleAssigneesToChat.length >= 2 && (
              <TkTypography
                fontWeight="bold"
                fontFamily="Lato"
                fontSize={16}
                color="primary"
                userselect="none"
                marginTop="15px"
                textAlign="center"
                style={{ cursor: 'pointer' }}
                onClick={handleStartChatWithAssignees}
              >
                <TkIcon
                  className="fa fa-comments-alt"
                  color="default"
                  iconSize={16}
                  width={18}
                  marginLeft={10}
                  marginRight={10}
                  style={{ cursor: 'pointer' }}
                />
                {t('message_all_teammates_assigned', { ns: 'common' })}
              </TkTypography>
            )}
          </TkTaskWrapperItem>
          <TkTaskWrapperItem padding="5px 1rem">
            <HorizontalDivider />
          </TkTaskWrapperItem>
          {selectedEvent.externalId &&
            getOfferAdditionalItems(selectedEvent, false).restItemsCount >
              0 && (
              <div style={{ padding: '.25rem 1rem' }}>
                <TkTaskWrapperItem
                  margin="0 -1rem"
                  padding="0 1rem"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleProjectAdditionalInfoTurno()}
                  bgcolor={
                    isProvidedDrawerOpen(
                      ITkExtendedOfferDetailsDrawerEnum.ADDITIONAL_INFO_TURNO
                    )
                      ? 'selected'
                      : ''
                  }
                >
                  <TkDrawerTurnoAdditionalInfo offer={selectedEvent} />
                </TkTaskWrapperItem>
                <Divider sx={{ marginTop: '.25rem' }} />
              </div>
            )}
          {isEventBooking && (
            <TkTaskWrapperItem
              padding="0.5rem"
              margin="0.5rem"
              style={{ cursor: 'pointer' }}
              onClick={handleProjectAdditionalInfo}
              bgcolor={
                isProvidedDrawerOpen(
                  ITkExtendedOfferDetailsDrawerEnum.ADDITIONAL_INFO_BOOKING
                )
                  ? 'filled'
                  : ''
              }
            >
              <TkTaskItem icon="fa fa-hashtag">
                <TkTypography fontSize={16}>
                  {t('additionalInfo', { ns: 'offers' })}
                </TkTypography>
              </TkTaskItem>
              <TkBookingProjectAdditionalInfoItem
                selectedEvent={selectedEvent}
              />
            </TkTaskWrapperItem>
          )}
          <TkTaskWrapperItem
            onClick={handleTaskNotes}
            bgcolor={
              isProvidedDrawerOpen(ExtendedDrawersTypes.EDIT_TASK_NOTES)
                ? 'selected'
                : ''
            }
            padding="10px 1rem"
          >
            <TkTypography
              fontSize={16}
              fontFamily="Lato"
              fontWeight="normal"
              color={
                isProvidedDrawerOpen(ExtendedDrawersTypes.EDIT_TASK_NOTES)
                  ? 'white'
                  : 'default'
              }
              display="inline"
            >
              <Grid container justifyContent="space-between">
                <Box width="auto" position="relative">
                  <Box fontSize={16} display="inline" marginRight="5px">
                    <TkIcon
                      className="fa fa-file"
                      color={
                        isProvidedDrawerOpen(
                          ExtendedDrawersTypes.EDIT_TASK_NOTES
                        )
                          ? 'white'
                          : 'default'
                      }
                      fontSize="inherit"
                    />
                  </Box>
                  {t('guidance_notes', { ns: 'common' })}:
                </Box>
                <Box fontSize={16}>
                  <TkEditTaskNotes
                    selected={isProvidedDrawerOpen(
                      ExtendedDrawersTypes.EDIT_TASK_NOTES
                    )}
                  />
                </Box>
              </Grid>
            </TkTypography>
          </TkTaskWrapperItem>
          <TkTaskWrapperItem padding="5px 1rem">
            <HorizontalDivider />
          </TkTaskWrapperItem>
          <TkTaskWrapperItem
            onClick={handleImages}
            bgcolor={
              isProvidedDrawerOpen(ExtendedDrawersTypes.EDIT_TASK_IMAGES)
                ? 'selected'
                : ''
            }
            padding="10px 1rem"
          >
            <TkTypography
              fontSize={16}
              fontFamily="Lato"
              fontWeight="normal"
              color={
                isProvidedDrawerOpen(ExtendedDrawersTypes.EDIT_TASK_IMAGES)
                  ? 'white'
                  : 'default'
              }
              display="inline"
            >
              <Grid container justifyContent="space-between">
                <Box width="auto">
                  <Box fontSize={16} display="inline" marginRight="5px">
                    <TkIcon
                      className="fa fa-images"
                      verticalAlign="middle"
                      width={19}
                      color={
                        isProvidedDrawerOpen(
                          ExtendedDrawersTypes.EDIT_TASK_IMAGES
                        )
                          ? 'white'
                          : 'default'
                      }
                      fontSize="inherit"
                    />
                  </Box>
                  {t('guidance_images', { ns: 'common' })}:
                </Box>
                {images.length > 0 ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    onClick={handleImages}
                  >
                    {images.map(
                      (i, index) =>
                        index <= 1 && (
                          <ImagePreviewCircle src={i.fullImageUrl} />
                        )
                    )}
                    {imagesFile.map(
                      (i, index) =>
                        index <= 1 && (
                          <ImagePreviewCircle src={URL.createObjectURL(i)} />
                        )
                    )}
                    <TkTypography
                      fontSize={16}
                      display="inline"
                      fontFamily="Lato"
                      fontWeight="bold"
                      textAlign="right"
                      style={{ cursor: 'pointer' }}
                      color={
                        isProvidedDrawerOpen(
                          ExtendedDrawersTypes.EDIT_TASK_IMAGES
                        )
                          ? 'white'
                          : 'default'
                      }
                      marginLeft={1}
                    >
                      {images.length + imagesFile.length}
                    </TkTypography>
                  </Box>
                ) : (
                  <Box onClick={handleImages}>
                    <TkTypography
                      fontSize={16}
                      fontWeight="bold"
                      fontFamily="Lato"
                      userselect="none"
                      color={
                        isProvidedDrawerOpen(
                          ExtendedDrawersTypes.EDIT_TASK_IMAGES
                        )
                          ? 'white'
                          : 'primary'
                      }
                      textAlign="right"
                      style={{ cursor: 'pointer' }}
                    >
                      {!isStatusDone ? (
                        imagesFile.length === 0 ? (
                          `+ ${t('images', { ns: 'common' })}`
                        ) : (
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            onClick={handleImages}
                          >
                            {imagesFile.map((i, index) =>
                              index <= 2 ? (
                                <ImagePreviewCircle
                                  src={URL.createObjectURL(i)}
                                />
                              ) : (
                                ''
                              )
                            )}
                            <TkTypography
                              fontSize={16}
                              display="inline"
                              fontFamily="Lato"
                              fontWeight="bold"
                              textAlign="right"
                              style={{ cursor: 'pointer' }}
                              color={
                                isProvidedDrawerOpen(
                                  ExtendedDrawersTypes.EDIT_TASK_IMAGES
                                )
                                  ? 'white'
                                  : 'default'
                              }
                              marginLeft={1}
                            >
                              {imagesFile.length}
                            </TkTypography>
                          </Box>
                        )
                      ) : (
                        images.length
                      )}
                    </TkTypography>
                  </Box>
                )}
              </Grid>
            </TkTypography>
          </TkTaskWrapperItem>
          <TkTaskWrapperItem padding="5px 1rem">
            <HorizontalDivider />
          </TkTaskWrapperItem>
        </TkTaskWrapper>
        {edited && (
          <TkTaskActions>
            <DynamicButton
              color="primary"
              disableElevation
              onClick={handleDismiss}
            >
              {t('cancel', { ns: 'common' })}
            </DynamicButton>
            <DynamicButton
              variant="contained"
              color="primary"
              disableElevation
              onClick={handleSave}
            >
              {t('saveChanges', { ns: 'events' })}
            </DynamicButton>
          </TkTaskActions>
        )}
      </>
    );
  }
};

export default TkEditTaskDrawer;
